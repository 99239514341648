import { Routes, Route } from "react-router-dom";

import { React, Suspense, lazy } from "react";
import ErrorPage from "./error-page.jsx";
import './App.css';
import Contact from "./routes/Contact.jsx";

const HomePage = lazy(() => import("./routes/Home.jsx"));
// const EventsPage = lazy(() => import("./routes/Event.jsx"));
// const ExhibitsPage = lazy(() => import("./routes/Exhibits.jsx"));
// const SingleExhibitPage = lazy(() => import("./routes/ExhibitPage.jsx"));

const App = () => {
  return (
    <div className="app">
      <Routes>
        <Route path="*" element={<ErrorPage />} />
        <Route
          path="/"
          element={
            <Suspense
              fallback={
                <div className="loader-container">
                  <div className="loader-container-inner">
                    <p>Loading</p>
                  </div>
                </div>
              }>
              <HomePage />
            </Suspense>
          }
        />
        <Route
          path="/contact"
          element={
            <Suspense
              fallback={
                <div className="loader-container">
                  <div className="loader-container-inner">
                    <p>Loading</p>
                  </div>
                </div>
              }>
              <Contact/>
            </Suspense>
          }
        />
      </Routes>
    </div>
  );
};

export default App;
