import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useLocation } from "react-router-dom";

const Navbar = () => {
  const [color, setColor] = useState(true);
  let location = useLocation();

  const changeColor = () => {
    //scroll points go up as the page is scrolled down
    if (window.scrollY <= 0) {
      setColor(true);
      // console.log("true");
      // console.log(window.scrollY);
    } else {
      setColor(false);
      // console.log("false");
      // console.log(window.scrollY);
    }
  };
  // every part of this function is necessary.
  //It won't work if you emit the ", true" parameter
  window.addEventListener("scroll", changeColor, true);

  return (
    <>
      <nav className={color ? "navbarComp" : "navbarScrolled"}>
        <div className="nav-container">
          <div style={{minWidth: '153px'}}>
          <img src="/global-images/logo.svg" className="logo" alt="aeon desing logo" />
          </div>
          <div className="nav-link-container">
            {location.pathname === "/" ? (
              <>
                <Link to={`/`} className="nav-link-topbar">
                  Home
                </Link>
                <Link to={`#features`} className="nav-link-topbar" smooth>
                  Features
                </Link>
                <Link to={`#SEO`} className="nav-link-topbar" smooth>
                  SEO
                </Link>
                <Link to={`#process`} className="nav-link-topbar" smooth>
                  Process
                </Link>
                <Link to={`#pricing`} className="nav-link-topbar" smooth>
                  Pricing
                </Link>
              </>
            ) : (
              <>
              <Link to={`/`} className="nav-link-topbar">
                Home
              </Link>
            </>
            )}
          </div>
          <Link to={`/contact`} className="navbar-engage">
            Get a quote
          </Link>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
