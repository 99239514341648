import { useState } from "react";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";

const ContactForm = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);
  const sendEmail = (e) => {
    e.persist();
    e.preventDefault();
    setIsSubmitting(true);
    emailjs.sendForm("service_ll3yaxv", "template_qjwjlyi", e.target, "iTReAF1ZASUODvXpZ").then(
      (result) => {
        setStateMessage("We've received your quote request and will get in contact with you as soon as possible");
        setIsSubmitting(false);
        navigate("?success");
        setTimeout(() => {
          setStateMessage(null);
        }, 5000); // hide message after 5 seconds
      },
      (error) => {
        setStateMessage("Something went wrong, please try again later");
        setIsSubmitting(false);
        setTimeout(() => {
          setStateMessage(null);
        }, 5000); // hide message after 5 seconds
      }
    );

    // Clears the form after sending the email
    e.target.reset();
  };
  return (
    <form onSubmit={sendEmail} className="quote-form">
      <input type="text" name="from_name" className="form-input" placeholder="Name" required/>
      <input type="email" name="reply_to" className="form-input" placeholder="Email Address" required/>
      <input type="text" name="business" className="form-input" placeholder="Business Name" required/>
      <input type="tel" maxLength="12" name="phone" className="form-input" placeholder="Phone Number" required/>
      <input type="text" name="referral" className="form-input" placeholder="Referral Code (Optional)" />
      <textarea name="message" placeholder="Additional Details"/>
      <button type="submit" value="Send" disabled={isSubmitting} style={{marginTop: '20px'}}>Submit</button>
      {stateMessage && <p>{stateMessage}</p>}
    </form>
  );
};
export default ContactForm;
