import { useState } from "react";
import emailjs from "@emailjs/browser";

const FooterContactForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);
  const sendEmail = (e) => {
    e.persist();
    e.preventDefault();
    setIsSubmitting(true);
    emailjs.sendForm("service_ll3yaxv", "template_ex61dq3", e.target, "iTReAF1ZASUODvXpZ").then(
      (result) => {
        setStateMessage("We've received your quote request and will get in contact with you as soon as possible");
        setIsSubmitting(false);
        setTimeout(() => {
          setStateMessage(null);
        }, 5000); // hide message after 5 seconds
      },
      (error) => {
        setStateMessage("Something went wrong, please try again later");
        setIsSubmitting(false);
        setTimeout(() => {
          setStateMessage(null);
        }, 5000); // hide message after 5 seconds
      }
    );

    // Clears the form after sending the email
    e.target.reset();
  };
  return (
    <form onSubmit={sendEmail} className="footer-form">
      <input type="email" name="reply_to" className="form-input" placeholder="Email Address" style={{marginBottom: '0', borderRadius: '0', borderTopLeftRadius: '400px', borderBottomLeftRadius: '400px', borderRight: 'none'}} required/>
      <button type="submit" value="Send" disabled={isSubmitting} className="footer-button">Submit</button>
      {stateMessage && <p>{stateMessage}</p>}
    </form>
  );
};
export default FooterContactForm;
