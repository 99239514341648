import FooterContactForm from "./FooterContactForm";

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <p>Ready to stand out?</p>
        <h2 id="footer-email">Let's Start</h2>
        <div className="info-section" style={{ marginTop: "40px" }}>
          <div className="left-column" style={{padding: '0'}}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }} className="footer-email-info">
              <img src="/global-images/mail.svg" alt="eon mail" style={{ margin: "0 0 40px 0" }} />
              <h2 style={{ margin: "0 0 40px 0" }} id="footer-email">hello@eonwebsites.com</h2>
            </div>
          <FooterContactForm />
          </div>
          <div className="footer-right-column">
            <img src="/global-images/mailbox.svg" alt="eon Mailbox" />
          </div>
        </div>
        <div className="footer-end">
            <p>All rights reserved Eon Websites LLC, 2024</p>
            <div className="footer-end-socials">
                <a href={'https://www.facebook.com/people/Eon-Websites/61558496021370/'} target='_blank' rel='noreferrer'><img src="/global-images/facebook.svg" alt="Facebook" /></a>
                <img src="/global-images/twitter.svg" alt="Twitter" />
                <img src="/global-images/indeed.svg" alt="Indeed" />
            </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
