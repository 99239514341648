import React, { useEffect } from "react";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const Contact = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
      },[])
  return (
    <>
      <div className="container">
        <Navbar />
        <div className="form-container">
          <div className="form-box">
            <div className="form-information">
              <p> Ready to make your business stand out?</p>
              <h2 style={{ marginTop: "0", padding: "0" }}>Speak with our team</h2>
              <h3 style={{ fontSize: "26px", fontFamily: "Eudoxus-Sans-Bold" }}>We can help you</h3>
              <ul>
                <li style={{ fontSize: "16px" }}>Explore how Eon can help your business</li>
                <li style={{ fontSize: "16px" }}>Design a solution that enhances your website experience</li>
                <li style={{ fontSize: "16px" }}>Share your business colors and passion through a beaufiful website</li>
                <li style={{ fontSize: "16px" }}>Get started with your Eon website!</li>
              </ul>
              <h3 style={{ fontSize: "26px", fontFamily: "Eudoxus-Sans-Bold", marginTop: "4rem" }}>Stand out today with:</h3>
              <div className="features-container" style={{ justifyContent: "flex-start", marginTop: "20px" }}>
                <div className="feature-box">
                  <div className="feature-square">
                    <img src="/global-images/speed.svg" alt="Increased Performance" />
                  </div>
                  <p>
                    Increased<br></br>Performance
                  </p>
                </div>
                <div className="feature-box">
                  <div className="feature-square">
                    <img src="/global-images/mobile.svg" alt="Increased Performance" />
                  </div>
                  <p>
                    Mobile<br></br>Optimization
                  </p>
                </div>
                <div className="feature-box">
                  <div className="feature-square">
                    <img src="/global-images/users.svg" alt="Increased Performance" />
                  </div>
                  <p>
                    Unique<br></br>Design
                  </p>
                </div>
              </div>
            </div>
            <ContactForm />
          </div>
        </div>
        <div className="info-section" id="features">
        <div className="left-column">
          <div className="detail-box">
            <img src="/global-images/dark-star.svg" style={{ width: "16px" }} alt="Dark Star" />
            <p>Grow with Eon</p>
          </div>
          <h2>
            Grow Your Business With<br></br>
            <b style={{ color: "#1EE984" }}>Eon Websites</b>
          </h2>
          <p >
          Eon Websites is a cutting-edge platform that takes your online presence to new heights. With sleek design and user-friendly features, our products are a must-have for anyone looking to make a lasting impression on the web.</p>

          <p >Experience the power of Eon Websites and take your business to new heights. Whether you are a small business owner, a creative artist, or a budding entrepreneur, our services are designed to elevate your brand and capture the attention of your audience.</p>

          <p >Take the first step towards a dynamic online presence with Eon Websites - where passion, functionality, and style converge to create a truly unforgettable experience. Get ready to stand out from the crowd and leave a lasting impression with Eon Websites by your side.
          </p>
        </div>
        <div className="right-column">
          <img src="/global-images/help.svg" style={{ maxWidth: "620px" }} alt="Grow Business with Eon" />
        </div>
      </div>
        <Footer />
      </div>
    </>
  );
};

export default Contact;
